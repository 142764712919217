.App {}

.navbar {
  background-color: rgb(188, 188, 188);
  min-height: 25px;
}

.navbar a {
  text-decoration: none;
  color: black;
  margin: 0ch 1ch;
  vertical-align: middle;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar a .active {
  background-color: aqua;
}


a:link { 
  text-decoration: none;   
}

.title {
  font-size: 200%;
  margin: 10px 10px;
}

.main-container {
  text-align: center;
  max-width: 52ch;
  margin: auto;
}

.wordList .matchCount {
  font-size: 80%;
  font-style: italic;
  color: grey;
}

.wordList .shortest {
  font-weight: bolder;
  margin: 3px auto 10px auto;
  cursor: pointer;
  display: inline-block;
}

.wordList .searchTitle {
  font-size: 100%;
  margin: 10px auto;
}

.wordList .word {
  cursor: pointer;
  display: inline-block;
}

.wordList .title {
  border-bottom: black solid;
  width: 14em;
  margin: 10px auto;
  font-size: 100%;
}

.active-word {
  font-size: 150%;
  text-align: center;
}

.verses {
  text-align: left;
  max-width: 70ch;
  margin: 10px;
}

.verses .back-button {
  font-size: 150%;
  cursor: pointer;
}

.verses .back-button img {
  vertical-align: middle;
}

.verses .page-buttons {
  font-size: 100%;
  color: blue;
  cursor: pointer;
}

.verses .page-buttons .back-button {
  text-align: left;
  font-size: 100%;
  float: left;
}

.verses .page-buttons .forward-button {
  text-align: right;
  font-size: 100%;
  float: right;
}


.verses .occurences {
  font-size: 80%;
  font-style: italic;
  color: grey;
  text-align: center;
}


.verses .back-text {
  vertical-align: middle;
}

.verse-container {
  margin: 2em auto;
  border-bottom: grey solid;
}

.verse-text {
  margin: 1em auto;
  font-size: 90%;
}